var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(
          "\n    Submit to " +
            _vm._s(_vm.$t("features_showcase_general_label")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "caption required" }, [
        _vm._v(
          "\n    You are one step away from sharing your showcase! Just fill out the form\n    below, add preview images and files. Fields marked * are required.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "caption-p" }, [
        _vm._v(
          "\n    Anything submitted to showcase may be visible to all employees so please refrain from submitting confidential information.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Submit"
            }`,
          },
        },
        [
          _c("fieldset", { staticStyle: { "margin-bottom": "0px" } }, [
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("h1", [_vm._v("Project Details")]),
                _vm._v(" "),
                _c("h5", [_vm._v("First Name*")]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "firstName",
                  attrs: {
                    model: _vm.firstName,
                    placeholder: "First Name",
                    "hide-asterisk": false,
                    required: "true",
                    validate: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.firstName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("h5", [_vm._v("Last Name*")]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "lastName",
                  attrs: {
                    model: _vm.lastName,
                    label: "Last Name",
                    placeholder: "Last Name",
                    validate: "true",
                    "hide-asterisk": false,
                    required: "true",
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.lastName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("h5", [_vm._v("Email Address*")]),
                _vm._v(" "),
                _c("TextInput", {
                  ref: "email",
                  attrs: {
                    model: _vm.email,
                    label: "Email Address",
                    placeholder: "Email Address",
                    validate: "true",
                    required: "true",
                    "hide-asterisk": false,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.email = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("fieldset", { staticClass: "brand-checkbox" }, [
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c("h5", [_vm._v("Brand*")]),
                _vm._v(" "),
                _c("p", [_vm._v("Select the Brand your project applies to.")]),
                _vm._v(" "),
                _c("CheckboxArrayInput", {
                  ref: "brand",
                  attrs: {
                    name: "brand",
                    model: _vm.brand,
                    list: _vm.brandSelectList,
                    validate: "true",
                    required: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.brand = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "bizUnit" },
            [
              _c("h5", [_vm._v("Business Unit/Function*")]),
              _vm._v(" "),
              _c("TextInput", {
                ref: "business",
                attrs: {
                  model: _vm.business,
                  label: "Business Unit/Function",
                  placeholder: "Business Unit/Function",
                  validate: "true",
                  required: "true",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.business = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "projTitle" },
            [
              _c("h5", [_vm._v("Project Title*")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Tell us the title of the project as it should appear on Showcase."
                ),
              ]),
              _vm._v(" "),
              _c("TextInput", {
                ref: "title",
                attrs: {
                  model: _vm.title,
                  label: "Project Title",
                  placeholder: "Project Title",
                  validate: "true",
                  required: "true",
                  maxlength: _vm.maxTitleChars,
                  "help-text": _vm.titleCharsLeft + " characters left",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.title = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "project-info" },
            [
              _c("h5", [_vm._v("Project Objective*")]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "description",
                attrs: {
                  placeholder: "Tell us about the project.",
                  model: _vm.description,
                  validate: "true",
                  required: "true",
                  label: "Project Objective",
                  maxlength: _vm.maxDescriptionChars,
                  "help-text": _vm.descriptionCharsLeft + " characters left",
                  "hide-asterisk": false,
                  rows: 6,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.description = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            {
              staticClass: "audience-checkbox",
              staticStyle: { "margin-bottom": "0px" },
            },
            [
              _c(
                "div",
                { staticClass: "user-info" },
                [
                  _c("h5", [_vm._v("Audience*")]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Select all that apply.")]),
                  _vm._v(" "),
                  _c("CheckboxArrayInput", {
                    ref: "audience",
                    attrs: {
                      name: "audience",
                      model: _vm.audience,
                      list: _vm.audienceSelectList,
                      validate: "true",
                      required: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.audience = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "insights-inspirations" },
            [
              _c("h5", [_vm._v("Insight and Inspirations*")]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "insight",
                attrs: {
                  model: _vm.insight,
                  validate: "true",
                  placeholder:
                    "Tell us about the audience, industry insights, or any other information that helped motivate the creative work behind this project.",
                  label: "Insight and Inspirations",
                  maxlength: _vm.maxInspirationChars,
                  "help-text": _vm.inspirationCharsLeft + " characters left",
                  "hide-asterisk": true,
                  rows: 6,
                  required: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.insight = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("h5", [_vm._v("Impact*")]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "impact",
                attrs: {
                  placeholder:
                    "What do you hope to accomplish with this project? If you already have measurable outcomes, please provide them here.",
                  model: _vm.impact,
                  validate: "true",
                  rows: 6,
                  label: "Impact",
                  maxlength: _vm.maxImpactChars,
                  "help-text": _vm.impactCharsLeft + " characters left",
                  "hide-asterisk": true,
                  required: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.impact = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            { staticClass: "agency-info" },
            [
              _c("h5", [_vm._v("Who created the work?*")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Provide name of creative agency, internal team or external firm."
                ),
              ]),
              _vm._v(" "),
              _c("TextInput", {
                ref: "projectAgency",
                attrs: {
                  model: _vm.projectAgency,
                  label: "",
                  placeholder: "Name",
                  validate: "true",
                  "hide-asterisk": false,
                  required: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.projectAgency = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("TextInput", {
                ref: "agencyEmail",
                attrs: {
                  model: _vm.agencyEmail,
                  label: "",
                  placeholder: "Email",
                  validate: "true",
                  "hide-asterisk": false,
                  required: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.agencyEmail = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            {
              staticClass: "deliverable-checkbox",
              staticStyle: { "margin-bottom": "0px" },
            },
            [
              _c(
                "div",
                { staticClass: "user-info" },
                [
                  _c("h5", [_vm._v("Deliverables*")]),
                  _vm._v(" "),
                  _c("p", [_vm._v("What was the final deliverable?")]),
                  _vm._v(" "),
                  _c("CheckboxArrayInput", {
                    ref: "deliverables",
                    attrs: {
                      name: "deliverables",
                      model: _vm.deliverables,
                      list: _vm.deliverablesSelectList,
                      validate: "true",
                      "hide-asterisk": false,
                      required: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.deliverables = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("h5", [_vm._v("Team Member Names*")]),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "teamMember",
                attrs: {
                  placeholder:
                    "Provide the names of the core team members involved in the project. ",
                  model: _vm.teamMember,
                  validate: "true",
                  required: "true",
                  maxlength: _vm.maxDescriptionChars,
                  rows: 6,
                  label: "Team Member Names",
                  "hide-asterisk": false,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.teamMember = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("h5", [_vm._v("Upload Related Images or Assets")]),
              _vm._v(" "),
              _c("p", { staticClass: "upload-p" }, [
                _vm._v(
                  "\n        Total size of files must not exceed 2GB.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                  "hide-asterisk": false,
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "fieldset",
            [
              _c("h5", [_vm._v("Related Links")]),
              _vm._v(" "),
              _c("p", { staticClass: "related-p" }, [
                _vm._v(
                  "\n        If you'd like to have a downloadable portion of your showcase, please link to the file here.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "e.g. Policies",
                  placeholder_url: "e.g. http://www.site.com/link",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }