var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-submission-info", class: _vm.classes },
    [
      _vm.brand
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Brand:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.brand,
                  expression: "brand",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.business
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Business Unit / Function:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.business,
                  expression: "business",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.audience
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Audience:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.audience,
                  expression: "audience",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.projectAgency
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Who Created the work:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.projectAgency,
                  expression: "projectAgency",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.deliverables
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Deliverable:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.deliverables,
                  expression: "deliverables",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.teamMember
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Team Members:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.teamMember,
                  expression: "teamMember",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.links
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Related Links:\n    "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "links" },
              _vm._l(_vm.links, function (link, index) {
                return _c("li", { key: index }, [
                  _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                    _vm._v(_vm._s(link.text)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }