var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subpage-layout submission" }, [
    _c(
      "div",
      { staticClass: "showcase-sidebar" },
      [
        _c("back-button", {
          attrs: {
            "showcase-id": _vm.showcaseId,
            "button-text":
              "Back to showcase" + (_vm.showcase && _vm.showcase.name),
          },
        }),
        _vm._v(" "),
        _c("info"),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showcase
      ? _c(
          "div",
          { staticClass: "info-container" },
          [
            _c(
              "div",
              { staticClass: "show-mobile" },
              [
                _c("back-button", {
                  attrs: {
                    "showcase-id": _vm.showcaseId,
                    "button-text":
                      "Back to showcase" + (_vm.showcase && _vm.showcase.name),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.submission && _vm.submission.title) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.isPublished ? _c("ShowcaseNavigationButtons") : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPublished
              ? _c("div", { staticClass: "showcase-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "action-item like",
                      on: {
                        click: function ($event) {
                          return _vm.toggleLike(_vm.submissionId)
                        },
                      },
                    },
                    [
                      !_vm.isLiked(_vm.submissionId)
                        ? _c("icons", { attrs: { name: "showcase-favorite" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked(_vm.submissionId)
                        ? _c("icons", {
                            class: { fill: _vm.isLiked(_vm.submissionId) },
                            attrs: { name: "showcase-favorite-fill" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLiked(_vm.submissionId)
                        ? _c("span", [_vm._v("Like")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked(_vm.submissionId)
                        ? _c("span", [_vm._v("Unlike")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "action-item follow",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFollow(_vm.submissionId)
                        },
                      },
                    },
                    [
                      _c("icons", {
                        class: { fill: _vm.isFollowed(_vm.submissionId) },
                        attrs: { name: "showcase-notifications" },
                      }),
                      _vm._v(" "),
                      !_vm.isFollowed(_vm.submissionId)
                        ? _c("span", [_vm._v("Follow")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFollowed(_vm.submissionId)
                        ? _c("span", [_vm._v("Unfollow")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "action-item share" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.mailToLink } },
                      [
                        _c("icons", { attrs: { name: "showcase-mail" } }),
                        _vm._v("\n          Share\n        "),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.sliderData
              ? _c(
                  "div",
                  [
                    _c("ShowcaseSlider", {
                      attrs: {
                        id: "showcase_submission_slider",
                        title: false,
                        slides: _vm.sliderData,
                        "slideshow-delay": "7000",
                        "show-controls": _vm.sliderData.length > 1,
                        "show-indicators": _vm.sliderData.length > 1,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [_vm._v("Project Objective")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.submission.description,
                      expression: "submission.description",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [_vm._v("Insight and Inspiration")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.insight,
                      expression: "insight",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [_vm._v("Impact")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.impact,
                      expression: "impact",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("info", { attrs: { classes: "show-mobile info-wrapper" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "submission-navigation" },
              [
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", {
                      on: { navClick: _vm.onNavClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }