var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "showcase-intro" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Brand Showcase\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle-home" }, [
          _vm._v(
            "\n      Do you have a project that carries that WOW factor? Something that inspires, sparks engagement, and personifies our Brand? \n      We want to see it! Submit your work for a chance to be featured on the Brand Center.\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "btn primary",
            attrs: {
              to: _vm.$generateRoute({
                name: "showcase.submit",
                params: {
                  showcaseId: _vm.showcaseId,
                },
              }),
            },
          },
          [_vm._v("\n      Submit\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "header",
      {
        staticClass: "page-region",
        attrs: { id: "page-primary-banner", "aria-label": "Page Banner Area" },
      },
      [
        _c(
          "div",
          {
            staticClass: "interior-page-banner-container full-width",
            attrs: { id: "interior-page-banner-container" },
          },
          [
            _c(
              "div",
              {
                staticClass: "interior-page-header is-admin",
                attrs: { id: "interior-banner", role: "banner" },
              },
              [
                _c("div", { staticClass: "inner" }, [
                  _c("div", { staticClass: "header-items" }, [
                    _c("h1", { staticClass: "title" }),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }