var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      staticClass: "admin-showcase-submission-form",
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": true,
        "show-save-button": false,
      },
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Your Information\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "firstName",
            attrs: {
              model: _vm.firstName,
              placeholder: "First name",
              label: "First Name",
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.firstName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "lastName",
            attrs: {
              model: _vm.lastName,
              placeholder: "Last name",
              label: "Last Name",
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.lastName = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "email",
            attrs: {
              model: _vm.email,
              label: "Email Address",
              placeholder: "email@domain.com",
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.email = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Brand\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "brand",
              attrs: {
                name: "brand",
                model: _vm.brand,
                list: _vm.brandSelectList,
                validate: "true",
                required: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.brand = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Business Unit / Function\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("TextInput", {
              ref: "business",
              attrs: {
                model: _vm.business,
                label: "",
                placeholder: "Business Unit / Function",
                required: "false",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  _vm.business = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Project Information\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "title",
            attrs: {
              model: _vm.title,
              required: "true",
              validate: "true",
              placeholder:
                "This title will be shown on the overview page so keep it short and clear (e.g. 'Corporate website'). Maximum of 50 characters, depending on word length.",
              maxlength: _vm.maxTitleChars,
              label: "Project Title",
              "help-text": _vm.titleCharsLeft + " characters left",
            },
            on: {
              "update:model": function ($event) {
                _vm.title = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "description",
            attrs: {
              placeholder:
                "Explain the who - audience targeted, what- was the medium, where - did this appear",
              model: _vm.description,
              required: "true",
              validate: "true",
              maxlength: _vm.maxDescriptionChars,
              rows: 4,
              label: "Project Objective",
              "help-text": _vm.descriptionCharsLeft + " characters left",
            },
            on: {
              "update:model": function ($event) {
                _vm.description = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Audience\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "audience",
              attrs: {
                name: "audience",
                model: _vm.audience,
                list: _vm.audienceSelectList,
                validate: "true",
                required: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.audience = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Insight\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "insight",
            attrs: {
              placeholder:
                "Explain the who - audience targeted, what- was the medium, where - did this appear",
              model: _vm.insight,
              validate: "true",
              maxlength: _vm.maxInsightChars,
              rows: 4,
              label: "Insignt and Inspirations",
              "help-text": _vm.insightCharsLeft + " characters left",
            },
            on: {
              "update:model": function ($event) {
                _vm.insight = $event
              },
            },
          }),
          _vm._v(" "),
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Impact\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "impact",
            attrs: {
              placeholder:
                "Explain the who - audience targeted, what- was the medium, where - did this appear",
              model: _vm.impact,
              validate: "true",
              maxlength: _vm.maxImpactChars,
              rows: 4,
              label: "Impact",
              "help-text": _vm.impactCharsLeft + " characters left",
            },
            on: {
              "update:model": function ($event) {
                _vm.impact = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Who created the work?\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "projectAgency",
            attrs: {
              model: _vm.projectAgency,
              label: "Who created the work?",
              placeholder: "Name",
              validate: "true",
              "hide-asterisk": false,
            },
            on: {
              "update:model": function ($event) {
                _vm.projectAgency = $event
              },
            },
          }),
          _vm._v(" "),
          _c("TextInput", {
            ref: "agencyEmail",
            attrs: {
              model: _vm.agencyEmail,
              label: "",
              placeholder: "Email",
              validate: "true",
              "hide-asterisk": false,
            },
            on: {
              "update:model": function ($event) {
                _vm.agencyEmail = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "subhead-2" }, [
          _vm._v("\n      Deliverables\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns" },
          [
            _c("CheckboxArrayInput", {
              ref: "deliverables",
              attrs: {
                name: "deliverables",
                model: _vm.deliverables,
                list: _vm.deliverablesSelectList,
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.deliverables = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Team Member Names\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "teamMember",
            attrs: {
              placeholder:
                "Explain the who - audience targeted, what- was the medium, where - did this appear",
              model: _vm.teamMember,
              required: "true",
              validate: "true",
              rows: 4,
              label: "Team Member Names",
            },
            on: {
              "update:model": function ($event) {
                _vm.teamMember = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Upload 1-10 image(s) and/or video(s)\n    "),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "active" }, [
            _vm._v(
              "\n      Upload JPG or PNG photo(s) that are less than 5MB each. The first photo\n      uploaded will be your featured photo. Please use a 16:9 ratio (1128px\n      wide by 632px tall). Videos must be submitted in MP4 format. File names\n      should not contain any spaces or special characters.\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.fileHashCollection && _vm.fileHashCollection.length
            ? _c("ShowcaseFileUpload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add file(s)",
                  "upload-files-demo": "uploadFilesDemo",
                  "initial-file-hash-collection": _vm.fileHashCollection,
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "subhead-2" }, [
            _vm._v("\n      Include any relevant weblinks, one per line\n    "),
          ]),
          _vm._v(" "),
          _c("showcase-links", {
            ref: "showcaseLinks",
            attrs: {
              links: _vm.links,
              placeholder_text: "e.g. Policies",
              placeholder_url: "e.g. http://www.site.com/link",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }